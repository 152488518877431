import React from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';

import pic01 from '../assets/images/about_us.jpg'
import pic02 from '../assets/images/iotex_network.jpg'
import paper from '../assets/images/paper.png'
import paper2 from '../assets/images/paper2.png'
import paper3 from '../assets/images/paper3.png'
import pic03 from '../assets/images/career.jpeg';
import pic04 from '../assets/images/media_coverge.jpeg';

class Homepage extends React.Component {
    render() {
        const siteTitle = "Hyperconnect";

        return <Layout>
            <Helmet title={siteTitle} />

            <section id="one" className="main style1">
              <div className="grid-wrapper">
                <div className="col-6">
                  <header className="major">
                    <h2>About Us</h2>
                  </header>
                  <p>
                    Hyperconnect is a startup focusing on cutting-edge technologies
                    including <b>auto-scalable blockchain, confidential computing 
                    and decentralized storage</b>. 
                    
                    Hyperconnect is the pioneer of the Internet of Trusted Things 
                    (IoTT), especially "<b>#OwnYourDeviceData</b>" vision and main contributor 
                    of <b>the open source project IoTeX</b>. We are a research and engineering 
                    driven team comprised of distinguished research scientists, 
                    top-tier engineers, and experienced ecosystem builders. 
                    
                    We push the frontier of technology and bring impactful innovations 
                    into the physical world.
                  </p>

                  <p> 
                    <b>
                      Hyperconnect Labs is in stealth mode and is focused on building consumer products enabling the vision of #OwnYourDeviceData.
                    </b>
                  </p>
                </div>
                <div className="col-6">
                  <span className="image fit">
                    <img src={pic01} alt="" />
                  </span>
                </div>
              </div>
            </section>


            <section id="two" className="main style2 special">
              <div className="grid-wrapper">
              
                <div className="col-12">
                  <header className="major">
                    <h2>Core Technology</h2>
                  </header>
                </div>

                <div className="col-4">
                  <h4 className="subtitle">
                    <strong>Auto-scalable Blockchain</strong>
                  </h4>
                  <p>
                    Support millions of transactions among thousands of nodes with instant finality and
                    low latency.
                  </p>
                  <p>
                    Innovative chains-in-chain structure to supporting heterogeneous layer2 chains for a wide range of applications.
                  </p>
                  <p>
                    Lightweight crypto enables high performance processing in an energy efficient way.
                  </p>
                </div>
                <div className="col-4">
                  <h4 className="subtitle">
                    <strong>Confidential Computing</strong>
                  </h4>
                  <p>
                    Enable encrypted data to be processed on the fly while lowering the risk of exposing it 
                    to the rest of the system.
                  </p>
                  <p>
                    Reduce the risk for sensitive data to be exposed while providing a higher degree of 
                    control and transparency for users.
                  </p>
                  <p>
                    Enable end-to-end control of users' data and ensure compliance with regulations like HIPAA, GDPR, CCPA and more.
                  </p>
                </div>
                <div className="col-4">
                  <h4 className="subtitle">
                    <strong>Decentralized Storage</strong>
                  </h4>
                  <p>
                    Store redundant data segments on nodes across the globe, eliminating single point of failure 
                    and ensuring 99.9% uptime.
                  </p>
                  <p>
                    Create a decentralized storage marketplace in which storage providers compete for applications and users pay to use.
                  </p>
                  <p>
                    API/SDK is compatible with that of cloud storage providers.
                  </p>
                </div>
              </div>
            </section>

            <section id="three" className="main style special">
              <div className="col-12">
                <header className="major">
                  <h2>Advanced Research</h2>
                </header>
              </div>
              <div className="grid-wrapper mt1">
                <div className="col-4">
                  <span className="image fit m0">
                    <a href="https://www.iotex.io/research" target="_blank">
                    <img src={paper} height="400" alt=""/>
                    </a>
                  </span>
                  <h3 className="align-center">Auto-scalable Blockchain</h3>
                </div>
                <div className="col-4">
                  <span className="image fit m0">
                    <a href="https://www.iotex.io/research" target="_blank">
                    <img src={paper2} height="400" alt=""/>
                    </a>
                  </span>
                  <h3 className="align-center">Faster Dual-key Stealth Address</h3>
                </div>
                <div className="col-4">
                  <span className="image fit m0">
                    <a href="https://www.iotex.io/research" target="_blank">
                    <img src={paper3} height="400" alt=""/>
                    </a>
                  </span>
                  <h3 className="align-center">Data Authenticity Protection</h3>
                </div>
              </div>
            </section>

            <section id="four" className="main style1 special">
              <div className="grid-wrapper">
                <div className="col-12">
                  <header className="major">
                    <h2>Learn More</h2>
                  </header>
                  <span>Catch up with our latest update</span>
                </div>

                <div className="col-4">
                  <span className="image fit">
                    <a href="/career" target="_blank">
                    <img src={pic02} alt="" />
                    </a>
                  </span>
                  <h3>IoTeX Network</h3>
                  <p>
                    Auto-scalable open source blockchain infra.
                  </p>
                  <ul className="actions">
                  </ul>
                </div>


                <div className="col-4">
                  <span className="image fit">
                    <a href="/career" target="_blank">
                    <img src={pic03} alt="" />
                     </a>
                  </span>
                  <h3>Careers</h3>
                  <p>
                    We are hiring! See our job openings.
                  </p>
                  <ul className="actions">
                  </ul>
                </div>

                <div className="col-4">
                  <span className="image fit">
                    <a href="https://www.google.com/search?q=iotex&tbm=nws" target="_blank">
                    <img src={pic04} alt="" />
                     </a>
                  </span>
                  <h3>Media Coverage</h3>
                  <p>
                    See how we change the world step by step.
                  </p>
                  <ul className="actions">
                  </ul>
                </div>
              </div>
            </section>
          </Layout>
    }
}

export default Homepage;
